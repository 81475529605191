import { Box, Container, Flex, Text } from "@chakra-ui/react";
import { Trans, useTranslation } from "react-i18next";

import Logo from "../../assets/svg/SvgVulkanFooter"; 
import { Link } from "react-router-dom";

const Footer = () => {
	const { t } = useTranslation();

	const currentYear = new Date().getFullYear();

	return (
		<footer>
			<Box bg="#3f3f3f" py="80px">
				<Container maxW="1220px">
					<Box
						display="flex"
						flexDir={{ base: "column", md: "row" }}
						justifyContent={{ base: "center", md: "space-between" }}
						alignItems={{ base: "center", md: "start" }}
						mb="143px">
						<Box mb={{ base: "70px", md: "0" }}>
							<Text mb="24px" fontSize="22px" fontWeight="400" color="white">
								<Trans>callUs</Trans>:
							</Text>
							<Link to={"tel:+996 (312) 882 263"} target={"_blank"}>
								<Text
									fontSize="14px"
									color="#9B9B9B"
									fontWeight="400"
									pb="15px">
									<Trans>phone</Trans>: +996 (312) 882 263
								</Text>
							</Link>
						</Box>
						<Box mb={{ base: "70px", md: "0" }}>
							<Logo />
						</Box>
						<Box>
							<Text mb="24px" fontSize="22px" fontWeight="400" color="white">
								<Trans>contactUs</Trans>:
							</Text>
							 <Link to={"mailto:vulkanplyus@mail.ru"} target={"_blank"}>
							 <Text fontSize="14px" color="#9B9B9B" fontWeight="400" pb="15px">
								vulkanplyus@mail.ru
							</Text></Link>
						</Box>
					</Box>
					<Flex
						flexDir="column"
						alignItems="center"
						justifyContent="center"
						textAlign={{ base: "center", md: "center" }}>
						<Text fontSize="14px" color="#9B9B9B" fontWeight="400">
							© 2011-{currentYear} «Vulkan Plus».
						</Text>
						 <Link to={"https://motionweb.kg/"} target={"_blank"}>
						 <Text
							fontSize="14px"
							textTransform="uppercase"
							color="orange"
							fontWeight="400"
							display="flex"
							alignItems="center"
							justifyContent={{ base: "center", md: "start" }}>
							<Trans>{t("developedInMotionWeb")}</Trans>
						</Text>
						</Link>
					</Flex>
				</Container>
			</Box>
		</footer>
	);
};

export default Footer;
