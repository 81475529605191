import React, { useEffect, useState } from "react";
import {
	AboutCompany,
	Advantages,
	Hero,
	OurPartners,
	 
	SendUs,
} from "../../components";
import CountryMap from "../../components/components-home/hoverCountry/CountryMap";

import Loading from "../../components/loading/Loading";

const Home = () => {
	const [load, setLoad] = useState(true);
	useEffect(() => {
		setLoad(false);
	}, []);
	return (
		<>
			{load ? (
				<Loading />
			) : (
				<>
					<Hero />
					<AboutCompany />
					<Advantages />
					{/* <OurProduction /> */}
					<CountryMap />
					{/* <OurPhotos /> */}
					<OurPartners />
					<SendUs />
				</>
			)}
		</>
	);
};

export default Home;
