import React, { useEffect, useState } from "react";
import {
  HeroComponent,
  Production,
  SendUs,
} from "../../components";
import GrindingHero from "../../assets/img/GrindingHero.webp";
import Loading from "../../components/loading/Loading";
import { useTranslation } from "react-i18next";

const GrindingPage = () => {
  const { t } = useTranslation();

  const text = t("grindingBalls");

  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={GrindingHero} />
          <Production text={text}/>
          <SendUs />
        </>
      )}
    </>
  );
};

export default GrindingPage;
