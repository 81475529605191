import React, { useEffect, useState } from "react";
import {
  HeroComponent,
  ModelPhotos,
  SendUs,
  Styrofoarm,
  VideoPlusText,
} from "../../components";
import ModelImg from "../../assets/img/model.webp";
import MOdelHero from "../../assets/img/modelHero.webp";
import Model1 from "../../assets/img/modelPage1.webp";
import Model2 from "../../assets/img/modelPage2.webp";
import Model3 from "../../assets/img/modelPage3.webp";
import Model4 from "../../assets/img/modelPage5.webp";
import Model5 from "../../assets/img/modelPage4.webp";
import Model6 from "../../assets/img/modelPage7.webp";
import Model7 from "../../assets/img/modelPage6.webp";
import Model8 from "../../assets/img/modelPage8.webp";
import Model9 from "../../assets/img/modelPage9.webp";
import Loading from "../../components/loading/Loading";
import { useTranslation } from "react-i18next";

const ModelPage = () => {
  const { t } = useTranslation();

  const text = t("modelCasting");
  const desc = t("gasification");

  const photoModel = [
    { src: Model1, type: "image" },
    { src: Model2, type: "image" },
    { src: Model3, type: "image" },
    { src: Model4, type: "image" },
    { src: Model5, type: "image" },
    { src: Model6, type: "image" },
    { src: Model7, type: "image" },
    { src: Model8, type: "image" },
    { src: Model9, type: "image" },
  ];

  const [load, setLoad] = useState(true);
  useEffect(() => {
    setLoad(false);
  }, []);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeroComponent text={text} image={MOdelHero} />
          <VideoPlusText text={text} desc={desc} image={ModelImg} />
          <Styrofoarm />
          <ModelPhotos photoModel={photoModel} />
          <SendUs />
        </>
      )}
    </>
  );
};

export default ModelPage;
