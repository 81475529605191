import { Box, Container, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

import "./style.css";

const Advantages = () => {
	const { t } = useTranslation();

	const materialForged = [
		{
			text: t("advantageText1"),
		},
		{
			text: t("advantageText2"),
		},
		{
			text: t("advantageText3"),
		},
		{
			text: t("advantageText4"),
		},
		{
			text: t("advantageText5"),
		},
	];

	return (
		<Box mb="100px">
			<Container maxW="1220px">
				<Text
					textAlign="center"
					mb="14px"
					color="#1B1A1A"
					fontWeight="700"
					fontSize="48px">
					<Trans>advantage</Trans>
				</Text>
				<Box w="100%" h="2px" bg="orange" mb="35px"></Box>

				<Box
					w="100%"
					display="flex"
					justifyContent="center"
					flexWrap="wrap"
					fontFamily="Roboto Slab"
					fontWeight="600"
					fontSize={["8px", "12px", "14px"]}>
					{materialForged.map((el, idx) => (
						<Box
							key={idx}
							w={{ md: "236px", base: "64px" }}
							display="flex"
							flexDirection="column">
							<Flex
								justifyContent="center"
								alignItems="center"
								w="100%"
								h="80px"
								border="solid 1px"
								p={{ md: 2, base: "1px" }}
								borderColor="rgba(245,167,0,1)">
								<Text fontSize={{ md: 16, base: 10 }} textAlign="center">
									{el.text}
								</Text>
							</Flex>
						</Box>
					))}
				</Box>
			</Container>
		</Box>
	);
};

export default Advantages;
