import {
	Box,
	Button,
	Container,
	Image,
	Input,
	Text,
	Textarea,
} from "@chakra-ui/react";
import React from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

import ImageContact from "../../../assets/image/contact.webp";
import Logo from "../../../assets/image/logo.webp";
// import LoadBlock from "../../loading/LoadBlock";
import i18n from "../../../i18n/Index";

import toast, { Toaster } from "react-hot-toast";

const ContactUs = () => {
	const { t } = useTranslation();

	const { register, handleSubmit, reset } = useForm();

	const messageModel = (data) => {
		let messageTG = `<b>Name:</b> ${data.username || "N/A"}\n`;
		messageTG += `<b>Email:</b> ${data.email || "N/A"}\n`;
		messageTG += `<b>Phone:</b> ${data.phone || "N/A"}\n`;
		messageTG += `<b>Country:</b> ${data.country || "N/A"}\n`;
		messageTG += `<b>Subject:</b> ${data.subject || "N/A"}\n`;
		messageTG += `<b>Message:</b> ${data.desc || "N/A"}\n`;
		return messageTG;
	};

	const onSubmit = async (data) => {
		try {
			await axios.post(
				`https://api.telegram.org/bot${"8125596400:AAGmMcBdPFXDWJRnFFPM5dt81JXFhEOhp3Q"}/sendMessage`,
				{
					chat_id: -1002332242813,
					parse_mode: "html",
					text: messageModel(data),
				}
			);
			toast.success(t("Message"));
			reset();
		} catch (error) {
			console.error("Error sending message:", error);
			toast.error("Failed to send the message. Please try again.");
		}
	};

	return (
		<>


			<Toaster position="top-right" />
			<Container maxW="1220px">
				<Box
					pos="relative"
					minH={["870px", "870px", "889px", "789px"]}
					mb={{ base: "400px", md: "0" }}>
					<Box
						maxW="1220px"
						rounded="4px"
						boxShadow="0px 3px 63px rgba(40, 40, 40, 0.11)"
						bg="white"
						position="absolute"
						left="0"
						right="0"
						mx="auto"
						top="-100px"
						display="flex"
						justifyContent="space-between"
						flexDir={{ base: "column", md: "row" }}
						alignItems="center"
						p={{ base: "15px", lg: "65px" }}
						pb={["", "10px", "80px"]}>
						<Box
							maxW={{ base: "350px", md: "305px", lg: "428.83px" }}
							pos="relative"
							mr="10px">
							<Image src={ImageContact} mb="10px" />
							<Box maxW="300px" mx="auto">
								<Image src={Logo} />
							</Box>
						</Box>
						<Box maxW="501.16px" pos="relative" py="20px">
							<Text
								textAlign="center"
								pb={["10px", "20px", "47px"]}
								fontSize="34px"
								fontWeight="400">
								<Trans>sendUsAMessage</Trans>
							</Text>
							<form
								onSubmit={handleSubmit(onSubmit)}
								style={{ width: "100%", position: "relative" }}>
								{/* {load && <LoadBlock />} */}
								<Input
									{...register("username", { required: true })}
									type="text"
									bg="#F4F4F4"
									placeholder={
										i18n.language === "ru" ? "Полное имя" : "Full Name"
									}
									rounded="50px"
									border="2px"
									py="20px"
									px="28px"
									fontSize="14px"
									// borderColor={errors.username ? "#FF0000" : "#F4F4F4"}
									color="#9B9B9B"
									mb="30px"
									focusBorderColor="orange"
									maxW="501px"
								/>
								<Input
									{...register("email", {
										required: true,
										pattern: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
									})}
									type="email"
									bg="#F4F4F4"
									placeholder="E-mail"
									rounded="50px"
									py="20px"
									px="28px"
									fontSize="14px"
									border="2px"
									// borderColor={errors.email ? "#FF0000" : "#F4F4F4"}
									color="#9B9B9B"
									mb="30px"
									focusBorderColor="orange"
									maxW="501px"
								/>
								<Input
									{...register("phone", { required: true })}
									type="tel"
									bg="#F4F4F4"
									placeholder={
										i18n.language === "ru" ? "Номер телефона" : "Phone number"
									}
									rounded="50px"
									py="20px"
									px="28px"
									fontSize="14px"
									border="2px"
									// borderColor={errors.phone ? "#FF0000" : "#F4F4F4"}
									color="#9B9B9B"
									mb="30px"
									focusBorderColor="orange"
									maxW="501px"
								/>
								<Input
									{...register("country", { required: true })}
									type="text"
									bg="#F4F4F4"
									placeholder={
										i18n.language === "ru" ? "Страна" : "The country"
									}
									rounded="50px"
									py="20px"
									px="28px"
									fontSize="14px"
									border="2px"
									// borderColor={errors.country ? "#FF0000" : "#F4F4F4"}
									color="#9B9B9B"
									mb="30px"
									focusBorderColor="orange"
									maxW="501px"
								/>
								<Input
									{...register("subject")}
									type="text"
									bg="#F4F4F4"
									placeholder={
										i18n.language === "ru"
											? "Тема сообщения"
											: "Message subject"
									}
									rounded="50px"
									py="20px"
									px="28px"
									fontSize="14px"
									border="2px"
									borderColor="#F4F4F4"
									color="#9B9B9B"
									mb="30px"
									focusBorderColor="orange"
									maxW="501px"
								/>
								<Textarea
									{...register("desc")}
									bg="#F4F4F4"
									rounded="10px"
									py="20px"
									px="28px"
									fontSize="14px"
									border="2px"
									borderColor="#F4F4F4"
									color="#9B9B9B"
									mb="39px"
									h="110px"
									focusBorderColor="orange"
									maxW="501px"></Textarea>
								<Button
									type="submit"
									bg="orange"
									rounded="50px"
									color="white"
									px="31px"
									py="28px"
									colorScheme="orange">
									<Trans>sendUsAMessage</Trans>
								</Button>
							</form>
						</Box>
					</Box>
				</Box>
			</Container>
		</>
	);
};

export default ContactUs;
