import { Container, Box, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const ContactUsComponent = () => {
  const { t } = useTranslation();

  return (
    <section>
      <Container maxW="1220px" py="20px">
        <Box
          display="flex"
          justifyContent={[
            "center",
            "center",
            "space-around",
            "space-around",
            "space-between",
          ]}
          flexWrap="wrap"
        >
          <Box w={["100%", "70%", "340px"]} mb="20px" mt="10px" mx="8px">
            <Text
              color="#000000"
              fontFamily="Roboto"
              fontWeight="500"
              fontSize="24px"
            >
              <Trans>{t("callUs")}</Trans>:
            </Text>
            <Box
              px="5px"
              pt="10px"
              pb="50px"
              mt="10px"
              border="solid"
              borderColor="rgba(245,167,0,1)"
              borderLeft="0px"
              borderRight="0px"
            >
              <Text
                color="#000000"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
              >
                <Trans>phone</Trans>: +996 (312) 882 263
              </Text>
              {/* <Text
                color="#000000"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
              >
                <Trans>mobile</Trans>: +996 (502) 221 074
              </Text> */}
            </Box>
          </Box>
          <Box w={["100%", "70%", "340px"]} mb="20px" mt="10px" mx="8px">
            <Text
              color="#000000"
              fontFamily="Roboto"
              fontWeight="500"
              fontSize="24px"
            >
              <Trans>contactUs</Trans>:
            </Text>
            <Box
              px="5px"
              pt="10px"
              pb="50px"
              mt="10px"
              border="solid"
              borderColor="rgba(245,167,0,1)"
              borderLeft="0px"
              borderRight="0px"
            >
              <Text
                color="#000000"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
              >
                vulkanplyus@mail.ru
              </Text>
              {/* <Text
                color="#000000"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="18px"
              >
                vulkancompany@gmail.com
              </Text> */}
            </Box>
          </Box>
          <Box w={["100%", "70%", "340px"]} mb="20px" mt="10px" mx="8px">
            <Text
              color="#000000"
              fontFamily="Roboto"
              fontWeight="500"
              fontSize="24px"
            >
              <Trans>ourAdress</Trans>:
            </Text>
            <Box
              px="5px"
              pt="10px"
              pb={["20px", "30px"]}
              mt="10px"
              border="solid"
              borderColor="rgba(245,167,0,1)"
              borderLeft="0px"
              borderRight="0px"
            >
              <Text
                color="#000000"
                fontFamily="Roboto"
                fontWeight="500"
                fontSize="15px"
              >
                <Trans>adress</Trans>
              </Text>
            </Box>
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default ContactUsComponent;
