import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { TbArrowNarrowUp } from "react-icons/tb";
import { FaFacebookMessenger, FaTelegramPlane } from "react-icons/fa";

import "./style.scss";
import { Link } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdMessage } from "react-icons/md";
import { useTranslation } from "react-i18next";

const IconUp = () => {
	const [fixedMenu] = useState(false);
	const [isHovered, setIsHovered] = useState(false);
	const { t } = useTranslation();

	const PHONE_NUMBER_WHATSAPP = "+996555131213";

	const WHATSAPP_LINK = `https://wa.me/${PHONE_NUMBER_WHATSAPP}`;
	const TELEGRAM_LINK = `https://t.me/${PHONE_NUMBER_WHATSAPP}`;


	const handleClick = () => {
		window.scroll(0, 0);
	};

	return (
		<Flex
			flexDir="column"
			position="fixed"
      zIndex="100"
			w={["40px", "50px"]}
			h={["100px", "100px"]}
			bottom={["0", "30px"]}
			right={["0", "20px"]}>
			<Box
				position="absolute"
				w={["40px", "50px"]}
				h={["40px", "50px"]}
				top={-30}
				bg="#f5a700"
				borderRadius="50%"
				cursor="pointer"
				display="flex"
				justifyContent="center"
				alignItems="center"
				onClick={handleClick}
				zIndex="50"
				fontSize="30px"
				color="white"
				className={fixedMenu ? "icon__up" : "icon__down"}>
				<TbArrowNarrowUp />
			</Box>

			<Box
				position="absolute"
				w={["40px", "50px"]}
				h={["40px", "50px"]}
				bg="#f5a700"
        p={["6px", "0px"]}
				borderRadius="50%"
				cursor="pointer"
				display="flex"
				justifyContent="center"
				alignItems="center"
				zIndex="100"
				fontSize="30px"
				color="white"
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}>
				<FaFacebookMessenger />
			</Box>

			{/* Всплывающее окно */}
			{isHovered && (
				<Flex
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
					w={260}
					position="absolute"
					top="-140px"
					right="50px"
					flexDir="column"
					gap={2}
					bg="white"
					p="10px"
					borderRadius="8px"
					boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
					zIndex="1000">
					<Link to={TELEGRAM_LINK} target={"_blank"}>
						<Flex
							p={2}
							borderRadius={4}
							_hover={{ bg: "#e9ecee" }}
							gap={2}
							alignItems="center">
							<Text
								bg="#7091ff"
								borderRadius={50}
								color="white"
								p={2}
								fontSize={26}>
								<FaTelegramPlane />
							</Text>
							<Text>Telegram</Text>
						</Flex>
					</Link>
					<Link to={WHATSAPP_LINK} target={"_blank"}>
						<Flex
							p={2}
							borderRadius={4}
							_hover={{ bg: "#e9ecee" }}
							gap={2}
							alignItems="center">
							<Text
								bg="#88ff70"
								borderRadius={50}
								color="white"
								p={2}
								fontSize={26}>
								<IoLogoWhatsapp />
							</Text>
							<Text>Whatsapp</Text>
						</Flex>
					</Link>

					<Link to="/contact">
						<Flex p={1} borderRadius={4} bg="black" gap={0} alignItems="center">
							<Text
								bg="black"
								borderRadius={50}
								color="white"
								p={2}
								fontSize={28}>
								<MdMessage />
							</Text>
							<Text color="white">{t("MessageIcon")}</Text>
						</Flex>
					</Link>
				</Flex>
			)}
		</Flex>
	);
};

export default IconUp;
