 
import {
  Box,
  Container,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

const Styrofoarm = () => {
  const { t } = useTranslation();
  const [openIndex, setOpenIndex] = useState(null); // Управление открытием аккордеона

 
	const accardionText = [
		{
			text: t("StyrofoarmText"),
			desc: t("StyrofoarmDesc"),
		},
		{
			text: t("StyrofoarmText1"),
			desc: t("StyrofoarmDesc1"),
		},
		{
			text: t("StyrofoarmText2"),
			desc: t("StyrofoarmDesc2"),
		},
		{
			text: t("StyrofoarmText3"),
			desc: t("StyrofoarmDesc3"),
		},
		{
			text: t("StyrofoarmText4"),
			desc: t("StyrofoarmDesc4"),
		},
		{
			text: t("StyrofoarmText5"),
			desc: t("StyrofoarmDesc5"),
		},
		{
			text: t("StyrofoarmText6"),
			desc: t("StyrofoarmDesc6"),
		},
		{
			text: t("StyrofoarmText7"),
			desc: t("StyrofoarmDesc7"),
		},
		{
			text: t("StyrofoarmText8"),
			desc: t("StyrofoarmDesc8"),
		},
	];

	const textdata = [
		{
			title: t("Economical"),
			desc: t("EconomicalDesc"),
		},
		{
			title: t("Accuracy"),
			desc: t("AccuracyDesc"),
		},
		{
			title: t("Versatility"),
			desc: t("VersatilityDesc"),
		},
		{
			title: t("Speed"),
			desc: t("SpeedDesc"),
		},
	];

  return (
    <section>
      <Container maxW="1220px">
        <Box>
          <Box
            border="solid"
            borderColor="rgba(245,167,0,1)"
            borderLeft="0px"
            borderRight="0px"
            borderTop="0px"
          >
            <Text
              textAlign="center"
              fontFamily="Roboto Slab"
              fontWeight="600"
              fontSize={["20px", "23px"]}
              lineHeight="34px"
              py={["20px", "30px"]}
              px={["10px", "20px"]}
            >
              <Trans>theMost</Trans>
            </Text>
          </Box>
          <Box>
            <Text
              textAlign="center"
              fontFamily="Oswald"
              fontWeight="700"
              fontSize={["35px", "48px"]}
            >
              <Trans>howWeAreWorking</Trans>
            </Text>
          </Box>
          <Flex flexDir="column" justifyContent="center" alignItems="center" py={10} w="100%">
            <Accordion display="flex" flexDir="column" justifyContent="center" alignItems="center" w="100%" allowToggle>
              {accardionText.map((el, index) => (
                <AccordionItem
                  w={{ base: "100%", md: 800 }}
                  key={index}
                  onMouseEnter={() => setOpenIndex(index)} // Устанавливаем открытый элемент
                  onMouseLeave={() => setOpenIndex(null)} // Сбрасываем открытие
                  isOpen={openIndex === index} // Проверяем, должен ли быть открыт
                >
                  <h2>
                    <AccordionButton py={4}>
                      <Text fontSize={18} flex="1" textAlign="left">
                        {el.text}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{el.desc}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>

          <Box>
            <Text
              textAlign="center"
              fontFamily="Oswald"
              fontWeight="700"
              fontSize={["35px", "48px"]}
            >
              <Trans>howWeAreWorking2</Trans>
            </Text>
          </Box>

          <Flex flexDir="column" justifyContent="center" alignItems="center" py={10} w="100%">
            <Accordion display="flex" flexDir="column" justifyContent="center" alignItems="center" w="100%" allowToggle>
              {textdata.map((el, index) => (
                <AccordionItem
                  w={{ base: "100%", md: 800 }}
                  key={index}
                  onMouseEnter={() => setOpenIndex(index)} // Устанавливаем открытый элемент
                  onMouseLeave={() => setOpenIndex(null)} // Сбрасываем открытие
                  isOpen={openIndex === index} // Проверяем, должен ли быть открыт
                >
                  <h2>
                    <AccordionButton py={4}>
                      <Text fontSize={18} flex="1" textAlign="left">
                        {el.title}
                      </Text>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>{el.desc}</AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </Flex>
        </Box>
      </Container>
    </section>
  );
};

export default Styrofoarm;

