import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import Slider from "react-slick";
import HeroImageBottom from "../../../assets/image/heroBottom.webp";

import SvgArm from "../../../assets/svg/SvgArm";
import SvgCalendar from "../../../assets/svg/SvgCalendar";
import SvgLike from "../../../assets/svg/SvgLike";
import SvgPeople from "../../../assets/svg/SvgPeople";
import SvgTimer from "../../../assets/svg/SvgTimer";
// import "./style.css";

const Hero = () => {
	const { t } = useTranslation();
	const settings = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 3500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4500,
	};

	const settingsList = {
		dots: false,
		arrows: false,
		infinite: true,
		speed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4500,
	};

	const HeroText = () => {
		const list = [
			{
				svg: <SvgTimer />,
				title: "workExperience",
			},
			{
				svg: <SvgPeople />,
				title: "professionalTeam",
			},
			{
				svg: <SvgArm />,
				title: "customer",
			},
			{
				svg: <SvgCalendar />,
				title: "high",
			},
			{
				svg: <SvgLike />,
				title: "quality",
			},
		];

		return (
			<Flex
				minH="80vh"
				// bg="red"
				w="100%"
				gap={10}
				flexDir="column"
				justifyContent="center"
				alignItems="center"
				position="absolute"
				pt={20}
				zIndex="1">
				<Box
					zIndex="1"
					top="0"
					left="0"
					display="flex"
					justifyContent="center"
					flexDirection="column"
					alignItems="center">
					<Text
						// className="text__vulkan"
						fontSize={["35px", "45px", "55px", "76px"]}
						fontWeight="700"
						textAlign="center"
						color="white">
						<Trans>companyName</Trans>
					</Text>
					<Text
						// className="text__vulkan"
						textAlign="center"
						fontSize={["15px", "25px", "35px", "46px"]}
						fontWeight="300"
						color="silver">
						<Trans>{t("companyDesc")}</Trans>
					</Text>
				</Box>

				  <Box display={{ md: "flex", base: "none" }}
						mt={20}
						gap={1}
						flexWrap="wrap"
						justifyContent="center"
						zIndex="2"
						w="100%"  
						position="relative"  
					>
						 
							{list.map((el, index) => (
								<Flex
									key={index}
									p="20px 0px"
									w={250}
									borderRadius={1}
									// bg="orange"
									flexDir="column"
									alignItems="center"
									justifyContent="center"
									transition="0.2s"
									// _hover={{background:"black",transition:"0.2s"}}
									mx="10px">
									<Box>{el.svg}</Box>

									<Text
										fontSize="18px"
										fontWeight="600"
										color="white"
										textAlign="center"
										px="10px"
										mt={4}>
										<Trans>{el.title}</Trans>
									</Text>
								</Flex>
							))}
					 
					</Box> 

				<Box display={{ md: "none", base: "Flex" }}
					mt={20}
					gap={3}
					flexDir="column"
					justifyContent="center"
					zIndex="2"
					w="100%"
					position="relative"
					p={10}>
					<Slider {...settingsList}>
						{list.map((el, index) => (
							<Flex
								key={index}
								p="20px 0px"
								w={{ md: 250, base: "70%" }}
								h={160}
								borderRadius={{ md: 4, base: "0" }}
								// bg="orange"
								>
								<Flex
									flexDir="column"
									alignItems="center"
									justifyContent="center">
									<Box>{el.svg}</Box>

									<Text
										fontSize="18px"
										fontWeight="600"
										color="white"
										textAlign="center"
										px="10px"
										mt={4}>
										<Trans>{el.title}</Trans>
									</Text>
								</Flex>
							</Flex>
						))}
					</Slider>
				</Box>
			</Flex>
		);
	};

	return (
		<section
			style={{ position: "relative", minHeight: "90vh", overflow: "hidden" }}
			className="media__hero">
			<Box position="relative" mb="50px">
				<HeroText />
				<Slider {...settings}>
					<Box
						w="100%"
						minH="90vh"
						display="flex"
						justifyContent="center"
						alignItems="center"
						className="media__hero--first"></Box>
					<Box
						w="100%"
						minH="90vh"
						display="flex"
						justifyContent="center"
						alignItems="center"
						className="media__hero--secondary"></Box>
					<Box
						w="100%"
						minH="90vh"
						display="flex"
						justifyContent="center"
						alignItems="center"
						className="media__hero--three"></Box>
					<Box
						w="100%"
						minH="90vh"
						display="flex"
						justifyContent="center"
						alignItems="center"
						className="media__hero--foure"></Box>
				</Slider>
			</Box>
			<Image
				src={HeroImageBottom}
				pos="absolute"
				bottom={{ base: "50px", md: "25px", lg: "22px", xl: "0" }}
			/>
		</section>
	);
};

export default Hero;
