export default function SvgArm() {
  return (
    <svg
      width="114"
      height="40"
      viewBox="0 0 114 70"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.9203 0.5H61.8031C60.3969 0.5 59.1664 1.02734 58.1117 2.08203L40.7094 17.9023C37.8969 20.7148 37.8969 25.1094 40.3578 27.7461C42.643 30.207 47.2133 30.9102 50.2016 28.2734C50.2016 28.2734 50.2016 28.2734 50.3773 28.2734L64.2641 15.4414C65.4945 14.3867 67.2523 14.3867 68.307 15.6172C69.3617 16.6719 69.3617 18.4297 68.1312 19.4844L63.5609 23.7031L89.225 44.4453C89.5766 44.9727 90.1039 45.3242 90.4555 45.8516V11.75L80.9633 2.25781C79.9086 1.20312 78.5023 0.5 76.9203 0.5ZM96.2562 11.9258V51.3008C96.2562 54.2891 98.7172 56.9258 101.881 56.9258H113.131V11.9258H96.2562ZM104.694 51.3008C103.112 51.3008 101.881 49.8945 101.881 48.4883C101.881 46.9062 103.112 45.6758 104.694 45.6758C106.1 45.6758 107.506 46.9062 107.506 48.4883C107.506 49.8945 106.1 51.3008 104.694 51.3008ZM0.631248 56.75H11.8812C14.8695 56.75 17.5062 54.2891 17.5062 51.125V11.9258H0.631248V56.75ZM9.06875 45.6758C10.475 45.6758 11.8812 46.9062 11.8812 48.4883C11.8812 49.8945 10.475 51.3008 9.06875 51.3008C7.48672 51.3008 6.25625 49.8945 6.25625 48.4883C6.25625 46.9062 7.48672 45.6758 9.06875 45.6758ZM85.5336 48.8398L59.3422 27.5703L54.0687 32.3164C48.7953 37.2383 40.8852 36.7109 36.1391 31.6133C31.5687 26.5156 31.9203 18.4297 37.018 13.6836L51.432 0.5H36.6664C35.0844 0.5 33.6781 1.20312 32.6234 2.25781L23.1312 11.75V51.125H26.2953L42.1156 65.5391C47.0375 69.582 54.0687 68.7031 57.9359 63.957H58.1117L61.1 66.5938C63.9125 68.8789 68.1312 68.5273 70.4164 65.7148L75.8656 58.8594L76.9203 59.7383C79.2055 61.6719 82.7211 61.3203 84.8305 58.8594L86.4125 56.75C88.3461 54.2891 87.9945 50.7734 85.5336 48.8398Z"
        fill="white"
      />
    </svg>
  );
}
