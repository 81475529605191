import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

const useIncrementOnNewYear = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    const now = new Date();
    const isJanuaryFirst = now.getMonth() === 0 && now.getDate() === 1;
    const lastIncrementYear = localStorage.getItem('lastIncrementYear');

    if (isJanuaryFirst && lastIncrementYear !== now.getFullYear().toString()) {
      setValue((prev) => prev + 1);
      localStorage.setItem('lastIncrementYear', now.getFullYear().toString());
    }
  }, []);

  return value;
};

const ModalAboutCompany = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const incrementedValue = useIncrementOnNewYear(12);

  return (
    <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxW={{ base: '90%', md: '735px' }}>
        <ModalHeader>
          <Text
            color="#1B1A1A"
            textAlign="center"
            fontWeight="700"
            fontSize="38px"
          >
            <Trans>AboutCompany</Trans>
          </Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody
          lineHeight="30.39px"
          fontWeight="400"
          fontSize="16px"
          textAlign="justify"
          px={{ base: '10px', sm: '30px' }}
        >
          <Text mb="15px">
            <Trans>theCompany</Trans>
          </Text>
          <Text mb="15px">
            {/* <Trans>theActive</Trans> */}

            {t("theActive1")}
        <span style={{ padding: "0 4px" }}>{incrementedValue}</span>
        {t("theActive2")}
          </Text>
          <Text mb="15px">
            <Trans>nextTech</Trans>
          </Text>
          <Text mb="15px">
            <Trans>earthLit</Trans>
          </Text>
          <Text mb="15px">
            <Trans>sehTerr</Trans>
          </Text>
          <Text mb="15px">
            <Trans>equippedWith</Trans>
          </Text>
          <Text mb="15px">
            <Trans>thermalFurnaces</Trans>
          </Text>
          <Text mb="15px">
            <Trans>qualityProducts</Trans>
          </Text>
          <Text mb="15px">
            <Trans>hardnessTester</Trans>
          </Text>
          <Text mb="15px">
            <Trans>productionAuxiliary</Trans>
          </Text>
          <Text mb="15px">
            <Trans>warehouseOccupies</Trans>
          </Text>
          <Text mb="15px">
            <Trans>scrapMetal</Trans>
          </Text>
          <Text mb="15px">
            <Trans>fullySupplied</Trans>
          </Text>
          <Text mb="15px">
            <Trans>finalCharacteristics</Trans>
          </Text>
          <Text mb="15px">
            <Trans>companyProduces</Trans>
          </Text>
          <Text mb="15px">
            <Trans>developmentOrder</Trans>
          </Text>
          <Text mb="15px">
            <Trans>stableWork</Trans>
          </Text>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            bg="orange"
            color="white"
            fontSize="16px"
            mr={3}
            onClick={onClose}
          >
            <Trans>btnClose</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ModalAboutCompany
