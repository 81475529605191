export default function SvgTimer() {
  return (
    <svg
      width="68"
      height="40"
      viewBox="0 0 68 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.8187 8.6875H65.5219C66.5766 8.6875 67.6312 7.80859 67.6312 6.57812V2.35938C67.6312 1.30469 66.5766 0.25 65.5219 0.25H2.24062C1.01015 0.25 0.131248 1.30469 0.131248 2.35938V6.57812C0.131248 7.80859 1.01015 8.6875 2.24062 8.6875H2.94375C2.94375 22.9258 8.56875 37.8672 19.9945 45.25C8.39297 52.8086 2.94375 67.75 2.94375 81.8125H2.24062C1.01015 81.8125 0.131248 82.8672 0.131248 83.9219V88.1406C0.131248 89.3711 1.01015 90.25 2.24062 90.25H65.5219C66.5766 90.25 67.6312 89.3711 67.6312 88.1406V83.9219C67.6312 82.8672 66.5766 81.8125 65.5219 81.8125H64.8187C64.8187 67.75 59.018 52.8086 47.5922 45.25C59.1937 37.8672 64.8187 22.9258 64.8187 8.6875ZM11.3812 8.6875H56.3812C56.3812 26.6172 46.1859 41.0312 33.8812 41.0312C21.4008 41.0312 11.3812 26.6172 11.3812 8.6875ZM56.3812 81.8125H11.3812C11.3812 64.0586 21.4008 49.4688 33.8812 49.4688C46.1859 49.4688 56.3812 64.0586 56.3812 81.8125Z"
        fill="white"
      />
    </svg>
  );
}
