import { Box } from '@chakra-ui/react'
import React from 'react'

const MapLocation = () => {
  return (
    <section>
      {/* <Box w="100%" className="media__hero">
        <iframe
          title="Bishkek"
          style={{ width: '100%' }}
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8026068.381132012!2d73.7503789218574!3d41.395950694178005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389eb7dc91b3c881%3A0x492ebaf57cdee27d!2z0JHQuNGI0LrQtdC6!5e0!3m2!1sru!2skg!4v1676022267259!5m2!1sru!2skg"
          width="600"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box> */}
      <Box
								borderRadius={20}
								// mt="60px"
								w="100%"
								h={330}
								overflow="hidden">
								<div className="map">
									<iframe
										src="/map.html"
										frameBorder="0"
										scrolling="no"
										title="Map on 2GIS"></iframe>
								</div>
							</Box>
    </section>
  )
}

export default MapLocation

 