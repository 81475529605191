export default function SvgLike() {
  return (
    <svg
      width="91"
      height="41"
      viewBox="0 0 91 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1625 39.625H5.1C2.63906 39.625 0.881248 41.5586 0.881248 43.8438V86.0312C0.881248 88.4922 2.63906 90.25 5.1 90.25H19.1625C21.4477 90.25 23.3812 88.4922 23.3812 86.0312V43.8438C23.3812 41.5586 21.4477 39.625 19.1625 39.625ZM12.1312 83.2188C9.67031 83.2188 7.9125 81.4609 7.9125 79C7.9125 76.7148 9.67031 74.7812 12.1312 74.7812C14.4164 74.7812 16.35 76.7148 16.35 79C16.35 81.4609 14.4164 83.2188 12.1312 83.2188ZM68.3812 14.6641C68.3812 1.65625 59.9437 0.25 55.725 0.25C52.0336 0.25 50.4516 7.28125 49.7484 10.4453C48.6937 14.3125 47.8148 18.1797 45.1781 20.8164C39.5531 26.6172 36.5648 33.8242 29.5336 40.6797C29.182 41.207 29.0062 41.7344 29.0062 42.2617V79.8789C29.0062 80.9336 29.8852 81.8125 30.9398 81.9883C33.7523 81.9883 37.4437 83.5703 40.2562 84.8008C45.8812 87.2617 52.7367 90.25 61.1742 90.25H61.7016C69.2602 90.25 78.225 90.25 81.7406 85.1523C83.3227 83.043 83.6742 80.4062 82.7953 77.2422C85.7836 74.2539 87.1898 68.6289 85.7836 64.0586C88.7719 60.0156 89.1234 54.2148 87.3656 50.1719C89.475 48.0625 90.8812 44.7227 90.7055 41.5586C90.7055 36.1094 86.1352 31.1875 80.3344 31.1875H62.4047C63.8109 26.2656 68.3812 22.0469 68.3812 14.6641Z"
        fill="white"
      />
    </svg>
  );
}
